<template>
  <nav
    class="navbar navbar-expand-lg sticky-top navbar-light bg-light headerBG"
  >
    <div class="container">
      <div class="col-lg-3">
        <a class="navbar-brand" href="#" @click.prevent="goto('Home')"
          ><img src="@/assets/flightese.png"
        /></a>
      </div>
      <div class="col-lg-6">
        <h2 class="headerH2">Air Ticket Booking Made Easy</h2>
      </div>
      <div class="col-lg-3">
        <h5 class="headerH5">
          <svg
            width="20"
            height="20"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.6667 40C32.8889 40 28.2319 38.9348 23.6956 36.8044C19.1578 34.6756 15.1393 31.8607 11.64 28.36C8.13926 24.8607 5.32445 20.8422 3.19556 16.3044C1.06519 11.7681 0 7.11111 0 2.33333C0 1.66667 0.222222 1.11111 0.666667 0.666667C1.11111 0.222222 1.66667 0 2.33333 0H11.3333C11.8519 0 12.3148 0.166666 12.7222 0.5C13.1296 0.833333 13.3704 1.25926 13.4444 1.77778L14.8889 9.55556C14.963 10.0741 14.9541 10.5459 14.8622 10.9711C14.7689 11.3978 14.5556 11.7778 14.2222 12.1111L8.88889 17.5556C10.4444 20.2222 12.3889 22.7222 14.7222 25.0556C17.0556 27.3889 19.6296 29.4074 22.4444 31.1111L27.6667 25.8889C28 25.5556 28.4356 25.3052 28.9733 25.1378C29.5096 24.9719 30.037 24.9259 30.5556 25L38.2222 26.5556C38.7407 26.6667 39.1667 26.9163 39.5 27.3044C39.8333 27.6941 40 28.1481 40 28.6667V37.6667C40 38.3333 39.7778 38.8889 39.3333 39.3333C38.8889 39.7778 38.3333 40 37.6667 40Z"
              fill="#052563"
            />
            &nbsp;</svg
          >Call Us Now
        </h5>
        <a class="getQouteA" href="tel:(844) 603 9978">(844) 603 9978 </a>
      </div>
    </div>
  </nav>
  <!--container-->
</template>

<script>
export default {
  name: 'MainHeader',
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
      setTimeout(function () {
        location.reload();
      }, 100);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lan="scss">
.headerH5 {
  color: #052563 !important;
  font-size: 14px;
  text-transform: uppercase !important;
  line-height: 20px;
  font-weight: 700;
  text-align: center;
}
.headerBG {
  background: radial-gradient(
    107.87% 107.87% at 50.03% 46.29%,
    #e4f0f7 0%,
    rgba(62, 104, 121, 0.55) 100%
  ) !important;
  height: 80px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
  .navbar-brand {
    color: #242f9b;
    img {
      height: 55px;
      margin-top: -5px;
    }
  }
  .navbar-nav {
    .nav-item {
      font-weight: 400;
      font-size: 16px;
      a {
        color: rgba(0, 0, 0, 1);
        transition-duration: 0.6s;
        text-transform: uppercase;
      }
      a:hover {
        color: #3798d4;
        transition-duration: 0.6s;
      }
      a::after {
        color: #3798d4;
        transition-duration: 0.6s;
      }
      .text {
        margin-top: 0px;
      }
    }
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    margin-top: 26px;
  }

  .dropdown-item {
    color: #fff !important;
    height: 40px;
    line-height: 33px;
  }
  .dropdown-item:hover {
    background-color: #00000040;
    color: rgba(255, 201, 60, 1) !important;
    transition-duration: 0.5s;
  }
  .getQouteA {
    border-radius: 5px;
    border: 3px solid #292341;
    padding: 4px 4px;
    color: #ffffff !important;
    text-decoration: none;
    background-color: #292341 !important;
    margin-top: 2px;
    width: 170px;
    display: block;
    margin: 0 auto;
    font-weight: 500;
    text-align: center;
  }
  .getQouteA:hover {
    box-shadow: rgba(149, 157, 165, 0.5) 0px 4px 12px;
    color: #ecf6fd !important;
  }
  .headerH2 {
    color: #052563 !important;
    font-size: 24px;
    text-transform: uppercase !important;
    line-height: 32px;
    font-weight: 700;
    margin-top: 7px;
    letter-spacing: 0.2rem;
    text-align: center;
  }
}
.homeSlide1 {
  background: #d9d9d9;
  .leftSide {
    h5 {
      color: #fff;
      font-family: Inter;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: left;
      animation: 0.7s ease-in 0s 1 slideInFromLeft;
    }
    h6 {
      color: #242f9b;
      font-family: Inter;
      font-size: 34px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      animation: 0.9s ease-in 0s 1 slideInFromLeft;
    }
    .row {
      margin-right: 0px;
    }
    .yellowButton {
      width: 220px;
      margin: 0 auto;
      display: block;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 50px;
      animation: 1.1s ease-in 0s 1 slideInFromLeft;
    }
  }
  .rightSide {
    .circle1 {
      animation: 0.8s ease-in 0s 1 slideInFromLeft;
      margin-top: 40px;
      img {
        width: 130%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
    .circle2 {
      animation: 1s ease-in 0s 1 slideInFromLeft;
      margin-top: 80px;
      img {
        width: 100%;
        filter: drop-shadow(0 0 0 #00000099);
      }
    }
    .circle3 {
      animation: 1.2s ease-in 0s 1 slideInFromLeft;
      margin-top: -80px;
      margin-left: -30px;
      img {
        width: 100%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
    .circle4 {
      animation: 1.4s ease-in 0s 1 slideInFromLeft;
      margin-top: -165px;
      margin-left: -20px;
      img {
        width: 120%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.yellowButton {
  padding: 14px 16px;
  border-radius: 8px;
  background: #292341;
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}
@media screen and (min-width: 1500px) {
  .headerH2 {
    font-size: 20px;
  }
}
@media screen and (max-width: 1200px) {
  .headerH2 {
    font-size: 20px !important;
  }
  .homeSlide1 {
    .leftSide {
      h5 {
        font-size: 32px;
      }
      h6 {
        font-size: 24px;
      }
    }
  }
  .rightSide {
    .circle4 {
      margin-top: -144px !important;
    }
  }
}
@media screen and (min-width: 992px) {
  .headerBG {
    .navbar-nav {
      width: 100%;
      .nav-item {
        width: 20%;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        .getQouteA {
          font-size: 14px;
          margin-top: 0px;
          display: inline-block;
        }
      }
    }
  }
  .dropdown-menu {
    background-color: #242f9b !important;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-top: 20px;
  }
}
@media screen and (max-width: 992px) {
  .headerH2 {
    display: none;
  }
  .getQouteA {
    width: 140px;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #292341 !important;
  }
  .headerBG {
    .getQouteA {
      width: 120px;
      font-size: 12px;
    }
  }

  .navbar-collapse {
    background-color: #3798d4dd;
    padding-left: 10px;
    margin-top: 41px;
    .nav-item {
      font-weight: 400;
      font-size: 16px;
      box-sizing: border-box;
      a {
        color: #ffffff !important;
      }
      .getQouteA {
        width: 98%;
        text-align: center;
        display: inline-block;
        color: #ffffff !important;
        box-sizing: border-box;
        margin-bottom: 10px;
      }
    }
  }
  .homeSlide1 {
    h5 {
      font-size: 48px;
      width: 100%;
      text-align: center;
    }
    h6 {
      font-size: 32px;
    }
  }
}
@media screen and (max-width: 540px) {
  .hideOnMobile {
    display: none;
  }
  .homeSlide1 {
    h5 {
      font-size: 32px;
      width: 100%;
      text-align: center;
    }
    h6 {
      font-size: 24px;
    }
  }
  .rightSide {
    .circle4 {
      margin-top: -30% !important;
      img {
        width: 100%;
      }
    }
  }
  .headerBG {
    .navbar-brand {
      color: #242f9b;
      img {
        height: 35px;
      }
    }
  }
}
</style>
