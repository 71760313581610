<template>
  <div class="container-flued homeSlide1">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 leftSide">
          <p>
            We appreciate your confidence in us and understand the importance of
            secure transactions and information privacy. This Privacy Statement
            defines how FlightESE, a travel-related service provider, collects,
            uses, shares, or otherwise processes your personal information via
            the website www.flightese.com.
          </p>
          <p>
            By reading this section and using our services, you confirm that you
            have read, understand, and agree that we have access to your
            personal information. This policy is subject to modification at any
            time and without notice. Please return to this page on a regular
            basis to see if any modifications have been made and to check that
            you still agree with them.
          </p>
          <h5>Personal information:</h5>
          <p>
            FlightESE will gather personal information from you, such as your
            name, postal address, phone number, email address, credit and debit
            card number, and other information. ID for a social media account,
            Passport number Any other information you provide when communicating
            or booking travel with us (e.g., your dietary and other preferences
            or needs), as well as information about your bookings (e.g., points
            of departure and destinations), Number assigned by a transportation
            agency, such as a Transportation Security Administration known
            traveler number Demographic information, Analytics/Advertising Data
            (for example, when linked to a user/device ID).
          </p>
          <h5>How We May Collect Personal Information:</h5>
          <p>
            Without information, we cannot assist you in planning and booking
            your dream vacation. So, when you use our services, we must collect,
            store, and exchange some personal data, and we ask that you consent
            to this policy. We acquire data in three ways:
          </p>
          <h5>You give it to us voluntarily:</h5>
          <p>
            The information you choose to provide us may include personal
            information required for you to book travel for yourself and others.
            This includes anything from the dates and location you select in a
            search to the fundamental information needed to make a booking with
            a Travel Supplier, such as names and contact information.
          </p>
          <h5>We collect it automatically:</h5>
          <p>
            As you use our services, we automatically produce or collect some
            information from your computer or device. This includes information
            such as your IP address, the device and browser you are using to
            access our services, the website URL you came from, and the
            third-party sites you visit when you click on links to leave the
            FlightESE site. It also contains information about the bookings you
            made via FlightESE. We may also be able to determine your location
            based on your mobile device or IP address. This allows us to
            optimize your experience and ensure you receive the most relevant
            content.
          </p>
          <h5>We receive it from Third Parties:</h5>
          <p>
            Through the usage of your browser or device, the Websites or Apps,
            Internet Protocol Address, we may acquire your device's actual
            position by using satellite, cell phone tower, or Wi-Fi signals, for
            example. We may utilize the physical location of your device to
            provide you with personalized location-based services and content.
          </p>
          <h5>How your Personal Information is Used :</h5>
          <p>
            To complete and fulfill your booking or purchase, including payment
            processing, communication with you about your booking or purchase,
            and related customer support.
          </p>
          <p>
            To reply to your inquiries and requests, such as sending you
            newsletters.
          </p>
          <p>
            To send you administrative information, such as Service information
            and updates to our terms, conditions, and policies.
          </p>
          <p>
            For business purposes such as data analysis, crash or other error
            remediation and prevention, audits, fraud monitoring and prevention,
            enhancing, improving, or modifying our Services, identifying usage
            trends, delivering and determining the effectiveness of our
            promotional campaigns (e.g., ads on our or third-party platforms and
            marketing emails.
          </p>
          <h5>How FightESE Shares Your Personal Data:</h5>
          <p>
            Other travel-related firms with which we collaborate to provide a
            component of the Services, such as airline agents offering access to
            their travel-related inventory through the Services, and travel
            insurance providers, to our business partners, whose products or
            services may be advertised on the Services, To our third-party
            service providers who offer website hosting, data analysis,
            advertising technology services, payment processing, order
            fulfillment, information technology, and other related services.
          </p>
          <h5>How Long Your Personal Data is Stored:</h5>
          <p>
            Unless a longer retention period is needed or authorized by law, we
            will keep your Personal Information for as long as we think it's
            necessary to accomplish the goals stated in this Privacy Policy.
          </p>
          <h5>Use of Services by Minor:</h5>
          <p>
            Children under the age of eighteen are not allowed to use our
            services, and no one under the age of eighteen should submit any
            information to, though, or on our services. We do not intentionally
            gather personal information from minors under the age of eighteen.
            We urge you to get in touch with us right away, and we'll try our
            hardest to get this information deleted from our databases as soon
            as possible.
          </p>
          <h5>Advertising:</h5>
          <p>
            When you visit our Platform, we employ third-party advertising
            businesses to show ads. These firms may use information about your
            visits to this and other websites (but not your name, address, email
            address, or phone number) to present advertising about goods and
            services of interest to you.
          </p>
          <h5>Use of Cookies:</h5>
          <p>
            A cookie (also known as an Internet/Web cookie) is a form of message
            sent by a web server to a web browser. A cookie's primary purpose is
            to identify users and maybe produce customized Web pages, as well as
            to save site login information for you. If our client is visiting
            our site for the first time, we will send him cookies. "What for?"
            you wonder. The solution is straightforward. We collect information
            about our clients' browser or search methods preferences.
          </p>
          <h5>Changes to this privacy policy:</h5>
          <p class="ppEndp">
            Please routinely review our privacy statement for updates. This
            Privacy Policy might be updated to reflect modifications to our
            information practices. We will notify you of any important changes
            by updating our website, www.flightese.com, with the date of the
            last amendment to our privacy policy. You will be considered to have
            accepted the Privacy Policy if you keep using any of the Services or
            have continuing access to them. For marketing and analytical
            purposes, we rely on cookies from third-party partners such as
            Google Analytics.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="container-flued homeSlide1">
    <div class="row">
      <div class="col-12">
        <h2>"Stay Prepared, Stay Protected</h2>
        <h5>Insurance for Life's Uncertainties"</h5>
        <h6>Your Shield in an Uncertain World"</h6>
        <a href="#" class="yellowButton">Get Your Quote</a>
      </div>
    </div>
  </div> -->
  <!--container-->
</template>

<script>
export default {
  name: 'PrivacyDesc',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lan="scss">
.homeSlide1 {
  background: rgba(242, 242, 242, 0.6);
  padding-bottom: 40px;
  .leftSide {
    padding-top: 40px;
    h2 {
      color: #f5f5f5;
      font-family: Inter;
      font-size: 80px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.2),
        0px -4px 5px rgba(255, 255, 255, 0.2);
      margin-top: 40px;
      animation: 0.5s ease-in 0s 1 slideInFromLeft;
      text-align: center;
    }
    h5 {
      color: #292341;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      /* animation: 0.7s ease-in 0s 1 slideInFromLeft; */
    }
    p {
      color: #292341;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      a {
        color: rgba(226, 184, 66, 0.91);
      }
    }
    .row {
      margin-right: 0px;
    }
    .yellowButton {
      width: 220px;
      margin: 0 auto;
      display: block;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 50px;
      animation: 1.1s ease-in 0s 1 slideInFromLeft;
    }
  }
  .rightSide {
    .circle1 {
      animation: 0.8s ease-in 0s 1 slideInFromLeft;
      margin-top: 40px;
      img {
        width: 100%;
        /* filter: drop-shadow(0 0 0.75rem #00000099); */
      }
    }
    .circle2 {
      animation: 1s ease-in 0s 1 slideInFromLeft;
      margin-top: 80px;
      img {
        width: 100%;
        filter: drop-shadow(0 0 0 #00000099);
      }
    }
    .circle3 {
      animation: 1.2s ease-in 0s 1 slideInFromLeft;
      margin-top: -80px;
      margin-left: -30px;
      img {
        width: 100%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
    .circle4 {
      animation: 1.4s ease-in 0s 1 slideInFromLeft;
      margin-top: -165px;
      margin-left: -20px;
      img {
        width: 120%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.yellowButton {
  padding: 14px 16px;
  border-radius: 8px;
  background: #ffc93c;
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}
ul {
  li {
    font-weight: 400;
  }
}
@media screen and (min-width: 1500px) {
  .homeSlide1 {
    h5 {
      text-align: center;
    }
  }
}
@media screen and (max-width: 1300px) {
  .homeSlide1 {
    .leftSide {
      h2 {
        font-size: 48px;
      }
      h5 {
        font-size: 18px;
      }
      h6 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .rightSide {
    .circle4 {
      margin-top: -144px !important;
    }
  }
}
@media screen and (min-width: 992px) {
  .headerBG {
    .navbar-nav {
      width: 100%;
      .nav-item {
        width: 20%;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        .getQouteA {
          font-size: 14px;
          margin-top: 0px;
          display: inline-block;
        }
      }
    }
  }
  .dropdown-menu {
    background-color: #242f9b !important;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-top: 20px;
  }
}
@media screen and (max-width: 992px) {
  .getQouteA {
    width: 160px;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #ffc93c;
  }

  .navbar-collapse {
    background-color: #242f9bee;
    padding-left: 10px;
    margin-top: 21px;
    .nav-item {
      font-weight: 400;
      font-size: 16px;
      box-sizing: border-box;
      a {
        color: #ffffff !important;
      }
      .getQouteA {
        width: 98%;
        text-align: center;
        display: inline-block;
        color: #242f9b !important;
        box-sizing: border-box;
        margin-bottom: 10px;
      }
    }
  }
  .homeSlide1 {
    .leftSide {
      h2 {
        font-size: 48px;
        margin-top: 40px;
      }
      h5 {
        font-size: 18px;
        width: 100%;
        /* text-align: center; */
      }
      h6 {
        font-size: 32px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}
@media screen and (max-width: 540px) {
  .hideOnMobile {
    display: none;
  }
  .homeSlide1 {
    h2 {
      font-size: 32px;
      margin-top: 40px;
    }
    h5 {
      font-size: 32px;
      width: 100%;
      /* text-align: center; */
    }
    h6 {
      font-size: 24px;
    }
  }
  .rightSide {
    .circle4 {
      margin-top: -30% !important;
      img {
        width: 100%;
      }
    }
  }
}
</style>
