<template>
  <div class="GameChanger">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="mainHeading">
              <img src="@/assets/plane.png" />
            </h3>
          </div>
          <div class="col-lg-6">
            <h3 class="mainHeading">Let’s Make <span>Memories</span></h3>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StartToday',
  props: {
    msg: String,
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.GameChanger {
  background: #070d5915;
  padding-bottom: 20px;
  padding-top: 40px;
}
.mainHeading {
  color: #052563;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 300%;
  text-align: left;
  span {
    font-weight: 700;
  }
  svg {
    margin-left: 20px;
  }
  img {
    float: right;
  }
}
@media screen and (max-width: 1300px) {
}
@media screen and (max-width: 992px) {
  .mainHeading {
    text-align: center;
    font-size: 24px;
    img {
      float: none;
      width: 100%;
    }
  }
}
</style>
