<template>
  <div class="RightDeals">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 flexVCenter mobilePaddingTop">
            <h4>Book Flight Tickets</h4>
            <h5>Right Now</h5>
          </div>
          <div class="col-lg-4">
            <img src="@/assets/pics3.png" class="fullWidthImg" />
          </div>
          <div class="col-lg-4 flexVCenter mobilePaddingBottom">
            <h4>Smart Fly With Us</h4>
            <a class="getQouteA" href="tel:(844) 603 9978"> Call Now </a>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DevelopBrands',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.flexVCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.getQouteA {
  border-radius: 10px;
  border: 3px solid #292341;
  padding: 15px 30px;
  color: #ffffff !important;
  text-decoration: none;
  background-color: #292341 !important;
  margin-top: 2px;
  display: block;
  margin: 0 auto;
  font-weight: 500;
  text-align: center;
}
.getQouteA:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.fullWidthImg {
  width: 100%;
}
.RightDeals {
  background: radial-gradient(
    107.87% 107.87% at 50.03% 46.29%,
    #e4f0f7 0%,
    rgba(62, 104, 121, 0.55) 100%
  );
  padding-bottom: 0px;
  padding-top: 0px;
  h4 {
    color: #070d59;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 107.143% */
    margin-top: 20px;
  }
  h5 {
    color: #f68319;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 107.143% */
    margin-top: 0px;
  }
  img {
    padding: 40px;
    width: 100%;
  }
  p {
    color: #070d59;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 137.5% */
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #73717b;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      display: inline-block;
    }
    h5 {
      color: #ffc93c;
      font-family: Open Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
    p {
      color: #fff;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: capitalize;
      margin-top: 10px;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
.fw500 {
  font-weight: 600 !important;
}
@media screen and (max-width: 1300px) {
  .RightDeals {
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 32px;
      margin-top: 0px;
    }
    h5 {
      font-size: 18px;
    }
  }
}
@media screen and (max-width: 992px) {
  .RightDeals {
    h3 {
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: left;
    }
    h4 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    h5 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .mobilePaddingTop {
      padding-top: 30px;
    }
    .mobilePaddingBottom {
      padding-bottom: 30px;
    }
  }
}
</style>
