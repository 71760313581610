<template>
  <div class="fourCards">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4>Our Management</h4>
          </div>
          <div class="col-lg-3">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src="@/assets/kamran.jpg" class="img-fluid" />
                </div>
                <div class="flip-card-back">
                  <h1>Mr. Kamran</h1>
                  <p>CEO</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src="@/assets/kamran.jpg" class="img-fluid" />
                </div>
                <div class="flip-card-back">
                  <h1>Col Asif</h1>
                  <p>Director</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img
                    src="@/assets/tayyaba.png"
                    style="margin-top: 15px"
                    class="img-fluid"
                  />
                </div>
                <div class="flip-card-back">
                  <h1>Tayyaba Javaid</h1>
                  <p>Design Lead</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src="@/assets/zainm.jpg" class="img-fluid" />
                </div>
                <div class="flip-card-back">
                  <h1>M. Zain Gulzar</h1>
                  <p>CTO</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OurManagement',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 335px;
  border: 1px solid #f1f1f1;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-card-back {
  background-color: #070d59;
  color: white;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    width: 100%;
    text-align: center;
    margin-top: 120px;
  }
  p {
    width: 100%;
    text-align: center;
    font-size: 24px;
  }
}
.img-fluid {
  width: 100%;
}
.fourCards {
  background: linear-gradient(
      180deg,
      #05387133 3.62%,
      rgba(65, 105, 150, 0.2) 100%
    ),
    #ffffff;
  padding-bottom: 40px;
  h4 {
    color: #070d59;
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  h5 {
    color: #ffc93c;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    margin-bottom: 30px;
  }
  .zCard {
    margin: 10px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #070d59;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    min-height: 320px;
    img {
      display: inline-block;
      height: 60px;
    }
    h5 {
      color: #fff;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 20px;
    }
    p {
      color: #fff;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 10px;
      text-align: justify;
      min-height: 160px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
@media screen and (max-width: 1300px) {
  .fourCards {
    .zCard {
      p {
        font-size: 14px;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .flip-card {
    background-color: transparent;
    width: 310px;
    height: 335px;
    border: 1px solid #f1f1f1;
    perspective: 1000px;
    margin: 0 auto;
    margin-top: 20px;
  }
  .fourCards {
    h4 {
      font-size: 32px;
    }
  }
}
</style>
