<template>
  <div class="Footer">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <!-- <div class="logo"><img src="@/assets/flightese.png" /></div> -->
            <h5>
              Find And Book An Amazing<br />
              Travel Experience With Us <br />Now
            </h5>
            <p>
              Copyright © 2024 ALL LEADS PROVIDER LLC. All rights reserved.<br />
              Flightese.com is owned and operated by All Leads Provider LLC.
            </p>
          </div>
          <div class="col-lg-2"></div>
          <div class="col-lg-4">
            <h5>
              Discover Your<br />
              <span
                >Dream Flight<br />
                Easily</span
              >
            </h5>
            <a class="getQouteA" href="tel:(844) 603 9978">Call Now </a>
          </div>
        </div>
      </div>
      <div class="container-fluid allRights">
        <div class="row">
          <div class="col-lg-12">
            ALL RIGHTS RESERVED |
            <span @click.prevent="goto('PrivacyPolicy')">PRIVACY POLICY</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterCom',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
      setTimeout(function () {
        location.reload();
      }, 100);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.getQouteA {
  border-radius: 10px;
  border: 3px solid #ffffff;
  padding: 15px 30px;
  color: #292341 !important;
  text-decoration: none;
  background-color: #ffffff !important;
  margin-top: 2px;
  display: block;
  // margin: 0 auto;
  width: 170px !important;
  font-weight: 500;
  text-align: center;
}
.getQouteA:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.Footer {
  background-color: #070d59;
  padding-bottom: 0px;
  padding-top: 60px;
  .logo {
    color: #242f9b;
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  h5 {
    color: white;
    font-size: 42px;
    font-weight: 400;
    span {
      font-weight: 900;
    }
  }
  p {
    color: #ffffff;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 125% */
    margin-top: 10px;
  }
  a {
    width: 100%;
    text-align: left;
    text-decoration: none;
    display: block;
    color: #070d59;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 0px;
  }
  .subFMenu {
    margin-top: 10px;
    margin-bottom: 5px;
    a {
      text-align: left;
      border-left: 2px solid #070d5966;
      margin-top: 5px;
      padding-left: 10px;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .allRights {
    background-color: #000000;
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 300%;
    text-transform: capitalize;
    text-align: center;
    margin-top: 30px;
    span {
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 992px) {
  .Footer {
    padding-top: 20px;
    padding-bottom: 0px;
    p {
      width: 100%;
      font-size: 16px;
    }
    a {
      text-align: left;
      margin-top: 10px;
    }
    h5 {
      font-size: 32px;
    }
    .allRights {
      font-size: 12px;
    }
  }
}
</style>
